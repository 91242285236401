<template>
  <v-container fluid class="mt-5">
    <v-row class="mb-8">
      <v-col>
        <h2>Dashboard Anexo I - SAC</h2>
      </v-col>
    </v-row>

    <v-tabs v-model="tab" fixed-tabs color="primary">
      <v-tab>
        Geral
      </v-tab>
      <v-tab>
        Visão ANEEL
      </v-tab>
      <v-tab>
        Municípios
      </v-tab>
    </v-tabs>

    <v-card class="my-0 parametros-v-card">
      <v-card-title>
        <h4>Parâmetros</h4>
      </v-card-title>
      <v-card-text>
        <v-row class="mx-auto mt-2" justify="start">
          <v-col cols="12" sm="6" md="4">
            <input-month
              label="Competência de"
              rules="min:7"
              :minMonth="'2023-01'"
              :selectedMonth="competenciaDe"
              @monthSelected="setCompetenciaDe"
            />
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <input-month
              label="Competência Até"
              rules="min:7"
              :minMonth="'2023-01'"
              :selectedMonth="competenciaAte"
              @monthSelected="setCompetenciaAte"
            />
          </v-col>
          <v-col class="pt-0" cols="12" sm="6" md="4">
            <v-autocomplete
              class="pl-4"
              :items="listaNiveis"
              v-model="niveisSelecionados"
              multiple
              small-chips
              label="Nível"
              clearable
              @change="niveisSelecionadosIsDirty = true"
              @blur="atualizaDados()"
            />
          </v-col>

          <v-col class="pt-0" cols="12" sm="6" md="4">
            <v-autocomplete
              class="pl-4"
              :items="listaTipologias"
              v-model="tipologiasSelecionadas"
              multiple
              label="Tipologias"
              clearable
              @change="tipologiasSelecionadasIsDirty = true"
              @blur="atualizaDados()"
            >
              <template v-slot:selection="{ item, index }">
                <v-chip small v-if="index < 2">
                  <span>{{ item.text }}</span>
                </v-chip>
                <span v-if="index === 2" class="grey--text text-caption">
                  (+{{ tipologiasSelecionadas.length - 2 }})
                </span>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col class="pt-0" cols="12" sm="6" md="4">
            <v-autocomplete
              class="pl-4"
              :items="listaSubtipologias"
              v-model="subtipologiasSelecionadas"
              multiple
              label="Subtipologias"
              clearable
              @change="subtipologiasSelecionadasIsDirty = true"
              @blur="atualizaDados()"
            >
              <template v-slot:selection="{ item, index }">
                <v-chip small v-if="index < 2">
                  <span>{{ item.text }}</span>
                </v-chip>
                <span v-if="index === 2" class="grey--text text-caption">
                  (+{{ subtipologiasSelecionadas.length - 2 }})
                </span>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col class="pt-0" cols="12" sm="6" md="4">
            <v-autocomplete
              class="pl-4"
              :items="listaCanais"
              v-model="canaisSelecionados"
              multiple
              label="Canal"
              clearable
              @change="canaisSelecionadosIsDirty = true"
              @blur="atualizaDados()"
            >
              <template v-slot:selection="{ item, index }">
                <v-chip small v-if="index < 3">
                  <span>{{ item }}</span>
                </v-chip>
                <span v-if="index === 3" class="grey--text text-caption">
                  (+{{ canaisSelecionados.length - 3 }})
                </span>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-tabs-items
      v-model="tab"
      style="background-color: transparent !important;"
    >
      <v-tab-item>
        <dashboard-anexo-1-geral
          ref="visao_geral"
          :competenciaDe="competenciaDe"
          :competenciaAte="competenciaAte"
          :activatedTab="tab"
          :traducaoIndicadores="traducaoIndicadores"
          :canaisSelecionados="canaisSelecionados"
          :niveisSelecionados="niveisSelecionados"
          :tipologiasSelecionadas="tipologiasSelecionadas"
          :subtipologiasSelecionadas="subtipologiasSelecionadas"
          @updateLoadingDados="loadingDados = $event"
        />
      </v-tab-item>
      <v-tab-item>
        <dashboard-anexo-1-visao-aneel
          ref="visao_aneel"
          :competenciaDe="competenciaDe"
          :competenciaAte="competenciaAte"
          :categories="categories"
          :activatedTab="tab"
          :traducaoIndicadores="traducaoIndicadores"
          :traducaoTipologias="traducaoTipologias"
          :traducaoSubTipologias="traducaoSubTipologias"
          :canaisSelecionados="canaisSelecionados"
          :niveisSelecionados="niveisSelecionados"
          :tipologiasSelecionadas="tipologiasSelecionadas"
          :subtipologiasSelecionadas="subtipologiasSelecionadas"
          @updateLoadingDados="loadingDados = $event"
        />
      </v-tab-item>
      <v-tab-item>
        <dashboard-anexo-1-visao-municipios
          ref="visao_municipios"
          :competenciaDe="competenciaDe"
          :competenciaAte="competenciaAte"
          :categories="categories"
          :activatedTab="tab"
          :traducaoIndicadores="traducaoIndicadores"
          :traducaoTipologias="traducaoTipologias"
          :traducaoSubTipologias="traducaoSubTipologias"
          :canaisSelecionados="canaisSelecionados"
          :niveisSelecionados="niveisSelecionados"
          :tipologiasSelecionadas="tipologiasSelecionadas"
          :subtipologiasSelecionadas="subtipologiasSelecionadas"
          @updateLoadingDados="loadingDados = $event"
        />
      </v-tab-item>
    </v-tabs-items>

    <v-overlay :value="loadingDados" :opacity="0.85">
      <v-progress-circular indeterminate color="primary" size="64" />
    </v-overlay>
  </v-container>
</template>

<script>
  import { generateMonthlyDateRangeArray } from "@/utils/dateUtils.js";

  import Highcharts from "highcharts";

  import HighchartsNoData from "highcharts/modules/no-data-to-display";
  import HighchartsBoost from "highcharts/modules/boost";
  import HighchartsExporting from "highcharts/modules/exporting";
  import HighchartsExportData from "highcharts/modules/export-data";
  import HighchartsDrilldown from "highcharts/modules/drilldown";
  import HighchartsData from "highcharts/modules/data";
  import HighchartsAccessibility from "highcharts/modules/accessibility";
  import { mapActions, mapState } from "vuex";

  HighchartsNoData(Highcharts);
  HighchartsBoost(Highcharts);
  HighchartsExporting(Highcharts);
  HighchartsExportData(Highcharts);
  HighchartsDrilldown(Highcharts);
  HighchartsData(Highcharts);
  HighchartsAccessibility(Highcharts);

  export default {
  name: "DashboardAnexo1",
  components: {
    InputMonth: () => import("@/components/general/InputMonth.vue"),
    DashboardAnexo1Geral: () =>
      import("@/components/dashboardAnexo1/DashboardAnexo1Geral.vue"),
    DashboardAnexo1VisaoAneel: () =>
      import("@/components/dashboardAnexo1/DashboardAnexo1VisaoAneel.vue"),
    DashboardAnexo1VisaoMunicipios: () =>
      import(
        "@/components/dashboardAnexo1/DashboardAnexo1VisaoMunicipios.vue"
      ),
  },
  data: () => ({
    tab: 1,
    loadingDados: false,
    canaisSelecionadosIsDirty: false,
    niveisSelecionadosIsDirty: false,
    tipologiasSelecionadasIsDirty: false,
    subtipologiasSelecionadasIsDirty: false,
    listaCanais: [
      "Redes Sociais",
      "Telefônico",
      "Presencial",
      "SMS",
      "ANEEL",
      "Outros",
      "Agęncia Virtual",
      "E-mail",
      "Consumidor.GOV",
      "Aplicativo",
    ],
    listaNiveis: ["Nível 1", "Nível 2"],
    traducaoIndicadores: {
      qrt: "Quantidade Relativa de Reclamações",
      prp: "Percentual de Reclamações Procedentes",
      pri: "Percentual de Reclamações Improcedentes",
    },
    traducaoTipologias: {
      "10201": "Conexão",
      "10202": "Cadastro / Contratos",
      "10203": "Medição",
      "10204": "Leitura / Faturamento / Fatura",
      "10205": "Cobranças",
      "10206": "Pagamento / Inadimplência / Suspensão",
      "10207": "Procedimento Irregular",
      "10208": "Atendimento / Estrutura de Atendimento",
      "10209": "Qualidade",
      "10210": "Ressarcimento de Danos Elétricos",
      "10211": "Outros Danos (não elétricos)",
      "10212": "Rede / Manutenção",
      "10213": "Geração Distribuída",
      "10214": "Prazos (não previstos nas tipologias anteriores)",
      "10215": "Instalações internas",
      "10216": "Iluminação Pública",
      "10217": "Outros - 10217",
      "10301": "Análise de Projetos",
      "10302": "Conexão de unidade consumidora",
      "10303": "Conexão de geração distribuída",
      "10304": "Conexão provisória",
      "10305": "Vistoria",
      "10306": "Ligação",
      "10307": "Comissionamento de obra",
      "10308": "Alteração de carga",
      "10309": "Alteração de nível de tensão",
      "10310": "Alteração da demanda contratada",
      "10311": "Encerramento contratual",
      "10312": "Inspeção (Aferição) de Medidor",
      "10313": "Verificação de nível de tensão",
      "10314": "Religação Normal",
      "10315": "Religação de Urgência",
      "10316": "Emissão de segunda via",
      "10317":
        "Emissão de segunda via da declaração anual de quitação de débitos",
      "10318": "Disponibilização de dados de medição em memória de massa",
      "10319": "Desligamento Programado",
      "10320": "Religação Programada",
      "10321": "Fornecimento de pulsos de potência e sincronismo",
      "10322": "Deslocamento ou remoção de poste / rede",
      "10323":
        "Solicitação de disponibilização de gravação de atendimento telefônico",
      "10324": "Informação da relação dos registros de atendimento prestados",
      "10325": "Religação em caso de suspensão indevida",
      "10326": "Instalação do padrão de entrada gratuito",
      "10327":
        "Alterações cadastrais, caso haja necessidade de visita técnica",
      "10328": "Alterações cadastrais",
      "10329": "Fornecimento de cópia do processo de ressarcimento de danos",
      "10330": "Fornecimento de cópia do processo de defeito na medição",
      "10331":
        "Fornecimento de cópia do processo de levantamento cadastral de iluminação pública",
      "10332": "Fornecimento de cópia do processo de irregularidade",
      "10333":
        "Disponibilizar informações do sistema de informação geográfica",
      "10334":
        "Fornecer informações sobre a arrecadação da contribuição para o custeio do serviço de",
      "10335":
        "informações relacionadas aos indicadores de continuidade, compensações e interrupções",
      "10399": "Outros - 10399",
      "10401": "Ligação Clandestina",
      "10402": "Fraude/Desvio de Energia Elétrica",
      "10403": "Contra empregado ou prestador de serviço da distribuidora",
      "10404": "Danos Ambientais",
      "10405": "Furtos de cabos e fiações elétricas",
      "10406": "Outros - 10406",
    },
    traducaoSubTipologias: {
      "1020101": "Solicitação não atendida ou atrasada",
      "1020102": "Orçamento - Participação Financeira / Universalização",
      "1020103": "Restituição de antecipação",
      "1020104": "Prazos (ligação com obras)",
      "1020105": "Prazos (ligação sem obras)",
      "1020199": "Outros",
      "1020201": "Cadastro",
      "1020202": "Uso não autorizado de dados cadastrais",
      "1020203": "Contratos / Encerramento contratual",
      "1020204": "Troca de titularidade",
      "1020299": "Outros",
      "1020301": "Ausência do medidor/sistema de medição",
      "1020302": "Avaria/Defeito medidor/sistema de medição",
      "1020303": "Lacre",
      "1020399": "Outros",
      "1020401": "Impedimento de acesso",
      "1020402": "Erro de leitura",
      "1020403": "Variação de consumo",
      "1020404": "Demais grandezas faturadas",
      "1020405": "Tarifas aplicadas",
      "1020406": "Classificação / Subsídios tarifários",
      "1020407": "Faturamento por estimativa / média",
      "1020408": "Faturamento pelo custo de disponibilidade",
      "1020409": "Compensação / Devolução não realizada / incorreta",
      "1020410": "Bandeiras Tarifárias",
      "1020411": "Tributos",
      "1020412":
        "Contribuição para o Custeio dos Serviços de Iluminação Pública - COSIP",
      "1020413": "Apresentação / Entrega da fatura",
      "1020414": "Qualidade da impressão da fatura",
      "1020499": "Outros",
      "1020501": "Acréscimos moratórios - Multas e juros",
      "1020502": "Cobranças de períodos anteriores",
      "1020503": "Parcelamento de débito",
      "1020504": "Serviços cobráveis",
      "1020505": "Atividades acessórias",
      "1020599": "Outros",
      "1020601": "Fatura paga e não baixada",
      "1020602":
        "Indisponibilidade / Inexistência de posto de arrecadação no Município",
      "1020603": "Suspensão indevida",
      "1020604": "Religação não realizada / fora do prazo",
      "1020605": "Inscrição em Cadastro de Negativação",
      "1020606": "Atuação de empresa de cobrança",
      "1020699": "Outros",
      "1020701": "Valores cobrados",
      "1020702": "Deficiência na caracterização da irregularidade",
      "1020703": "Não recebimento do TOI - Termo de Ocorrência e Inspeção",
      "1020704": "Responsabilidade sobre a Irregularidade",
      "1020705": "Cobrança decorrente de religação à revelia",
      "1020799": "Outros",
      "1020801": "Conduta de empregado ou prestador de serviço",
      "1020802":
        "Atendimento Presencial / Falta de agência / posto de atendimento",
      "1020803": "Atendimento Telefônico",
      "1020804": "Atendimento pela Internet",
      "1020805": "Demais canais de acesso",
      "1020899": "Outros",
      "1020901": "Interrupção no Fornecimento - Falta de energia",
      "1020902": "Interrupção Frequente do Fornecimento",
      "1020903": "Interrupção Programada",
      "1020904": "Tensão de Fornecimento",
      "1020999": "Outros",
      "1021001": "Danos em equipamentos",
      "1021002": "Vistoria dos Equipamentos",
      "1021003": "Laudo/orçamento",
      "1021004": "Ressarcimento inferior ao devido",
      "1021005": "Indeferimento total / parcial",
      "1021099": "Outros",
      "1021201": "Cabo partido",
      "1021202": "Poste",
      "1021203": "Transformador",
      "1021204": "Objeto na rede",
      "1021205": "Poda de árvore",
      "1021206": "Entulhos / Galhos de árvores não recolhidos",
      "1021299": "Outros",
      "1021301": "Conexão",
      "1021302": "Faturamento",
      "1021303": "Variação de Consumo",
      "1021304": "Apresentação / Entrega de Fatura",
      "1021399": "Outros",
    },
  }),
  computed: {
    ...mapState('parametrosDashboardAnexo1', [
      'competenciaDe',
      'competenciaAte',
      'niveis',
      'tipologias',
      'subtipologias',
      'canais'
    ]),
    niveisSelecionados: {
      get() {
        return this.niveis;
      },
      set(newValue) {
        this.setNiveis(newValue);
      },
    },
    tipologiasSelecionadas: {
      get() {
        return this.tipologias;
      },
      set(newValue) {
        this.setTipologias(newValue);
      },
    },
    subtipologiasSelecionadas: {
      get() {
        return this.subtipologias;
      },
      set(newValue) {
        this.setSubtipologias(newValue);
      },
    },
    canaisSelecionados: {
      get() {
        return this.canais;
      },
      set(newValue) {
        this.setCanais(newValue);
      },
    },
    categories() {
      const startDate = this.competenciaDe;
      const endDate = this.competenciaAte;

      return generateMonthlyDateRangeArray(startDate, endDate, "YYYY/MM");
    },
    listaTipologias() {
      let listaTipologias = [];

      for (const codTipologia in this.traducaoTipologias) {
        listaTipologias.push({
          value: codTipologia,
          text: `${codTipologia} - ${this.traducaoTipologias[codTipologia]}`,
        });
      }

      return listaTipologias;
    },
    listaSubtipologias() {
      let listaTipologias = [];

      for (const codSubtipologia in this.traducaoSubTipologias) {
        listaTipologias.push({
          value: codSubtipologia,
          text: `${codSubtipologia} - ${this.traducaoSubTipologias[codSubtipologia]}`,
        });
      }

      return listaTipologias;
    },
  },
  mounted() { },
  methods: {
    ...mapActions('parametrosDashboardAnexo1', [
      'setCompetenciaDe',
      'setCompetenciaAte',
      'setNiveis',
      'setTipologias',
      'setSubtipologias',
      'setCanais'
    ]),
    atualizaDados() {
      if (
        !this.canaisSelecionadosIsDirty &&
        !this.niveisSelecionadosIsDirty &&
        !this.tipologiasSelecionadasIsDirty &&
        !this.subtipologiasSelecionadasIsDirty
      ) {
        return;
      }

      this.$refs?.visao_geral?.getDados();
      this.$refs?.visao_aneel?.getDados();
      this.$refs?.visao_municipios?.getDados();

      this.canaisSelecionadosIsDirty = false;
      this.niveisSelecionadosIsDirty = false;
      this.tipologiasSelecionadasIsDirty = false;
      this.subtipologiasSelecionadasIsDirty = false;
    }
  },
  watch: {},
  };
</script>

<style>
  .parametros-v-card {
    box-shadow: 0px 0px 0px !important;
  }
</style>
